/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import DescriptionPart from '../../components/ListingDetails/DescriptionPart/DescriptionPart';
import ImagePart from '../../components/ListingDetails/ImagePart/ImagePart';
import MainBox from '../../components/ListingDetails/MainBox/MainBox';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  clearListingState,
  getListingQNA,
  getListingReviews,
  listingDetails,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import AttributeDetails from '../../components/ListingDetails/AttributeDetails/AttributeDetails';
import Head from 'next/head';
import AddressBox from '../../components/ListingDetails/AddressBox/AddressBox';
import Variants from '../../components/ListingDetails/Variants/Variants';
import ProductButtons from '../../components/ListingDetails/ProductButtons/ProductButtons';
import StoreNameBox from '../../components/ListingDetails/StoreNameBox/StoreNameBox';
import CustomImagePart from '../../components/ListingDetails/ImagePart/CustomImagePart';
import CustomProductButton from '../../components/ListingDetails/ProductButtons/CustomProductButton';
import RelatedListings from '../../components/ListingDetails/RelatedListing/RelatedListings';
import { check_login } from '../../constant/check_auth';
import AccountListings from '../../components/ListingDetails/AccountListings/AccountListings';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Edit_listing_button from '../../components/Shared/Buttons/Edit_listing_button';
import ShareButtons from '../../components/ListingDetails/ShareButtons/ShareButtons';
import ShippingMethods from '../../components/ListingDetails/ShippingMethods/ShippingMethods';
import QNA_section from '../../components/ListingDetails/QNA/QNA_section';
import Cookies from 'js-cookie';
import ImagePart2 from '../../components/ListingDetails/ImagePart/ImagePart2';
import CustomProductButton2 from '../../components/ListingDetails/ProductButtons/CustomProductButton2';
import RelatedListings3 from '../../components/ListingDetails/RelatedListing/RelatedListings3';
import AccountListings3 from '../../components/ListingDetails/AccountListings/AccountListings2';
import NotFound from '../../components/Shared/Default UI/NotFound';
import { Listing_text } from '../../constant/Translation/Listing';
import { slug as SlugChecker } from 'github-slugger';
import ReportButton from '../../components/ListingDetails/report/ReportButton';
import SuccessPopUp from '../../components/Shared/PopUp/Success';
import { General_text } from '../../constant/Translation/General';
import { Menubar_text } from '../../constant/Translation/Heading';

const CustomProductDetailsPageLayout = ({}) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const [selectedVariant, setSelectedVariant] = useState(null);

  const router = useRouter();

  const dispatch = useDispatch();
  const { auth_key, login } = useSelector(authSelector);

  const {
    isSuccess,
    listing_details,
    rating_data,
    errorMessage,
    isError,
    isShowSuccess,
    success_message,
  } = useSelector(listingSelector);

  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
        }
      });
    }
  };

  //
  useEffect(() => {
    if (listing_details) {
      dispatch(
        getListingReviews({
          authKey: auth_key,
          params: {
            type: 'listings',
            id: listing_details.id,
            page: 1,
          },
        })
      );
      dispatch(
        getListingQNA({
          params: {
            reference_type: 'listings_qa',
            reference_id: listing_details.id,
            page: 1,
            per_page: 10,
            parent: 0,
          },
        })
      );
    }
  }, [listing_details]);

  const closePopUP = () => {
    dispatch(clearListingState());
    setShowError(false);
    setError_message('');
  };

  return (
    <div
      className={['  flex flex-col gap-page_default  w-full mx-auto '].join(
        ' '
      )}
    >
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearListingState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/* Success popup */}
      {isShowSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            isShowSuccess && dispatch(clearListingState());
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp message={success_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details?.account_id && (
          <div className="flex items-center justify-end   ">
            <Edit_listing_button listing_details={listing_details} />
          </div>
        )}

      {listing_details !== undefined ? (
        <>
          <div className="   grid grid-cols-1  md:grid-cols-2  gap-page_default  ">
            <div className="flex flex-col gap-page_default   ">
              <div>
                <ImagePart2
                  images={
                    selectedVariant
                      ? listing_details?.variants?.filter(
                          (vr) => vr.id == selectedVariant
                        )[0].images.length > 0
                        ? listing_details?.variants?.filter(
                            (vr) => vr.id == selectedVariant
                          )[0].images
                        : listing_details?.images
                      : listing_details?.images
                  }
                  showThumbnails={false}
                />
              </div>
              {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
                listing_details?.description !== '' && (
                  <div className="  hidden md:block">
                    <DescriptionPart
                      description={listing_details?.description}
                    />
                  </div>
                )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_qa_enabled != false && (
                <div className="   hidden  md:block" id={'qa'}>
                  <QNA_section listing_details={listing_details} />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-page_default ">
              <div>
                <MainBox
                  listing_details={listing_details}
                  rating_data={rating_data}
                  like={like}
                />
              </div>
              {listing_details?.variants?.length > 0 && (
                <div className=" ">
                  <Variants
                    variants={listing_details.variants}
                    setSelectedVariant={setSelectedVariant}
                    selectedVariant={selectedVariant}
                  />
                </div>
              )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.main_buttons != false &&
                TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button?.link && (
                  <div className=" fixed bottom-0 w-full left-0 right-0 bg-white sm:relative md:bg-transparent z-[60] sm:z-30 md: ">
                    <CustomProductButton2
                      attributes={listing_details?.attributes}
                    />
                  </div>
                )}
              {listing_details?.account &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.account_widget != false && (
                  <div className=" ">
                    <StoreNameBox account={listing_details?.account} />
                  </div>
                )}

              {listing_details?.location &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.location_widget != false &&
                Object.keys(listing_details?.location).length > 0 && (
                  <div className=" ">
                    <AddressBox location={listing_details?.location} />
                  </div>
                )}

              {listing_details?.attributes &&
                listing_details?.attributes.length > 0 &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_attribute_section !=
                  false && (
                  <div className=" ">
                    <AttributeDetails
                      attributes={listing_details?.attributes}
                    />
                  </div>
                )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
                listing_details?.description !== '' && (
                  <div className="  md:hidden">
                    <DescriptionPart
                      description={listing_details?.description}
                    />
                  </div>
                )}

              {listing_details?.account?.shipping_methods?.length > 0 &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !==
                  true &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.shipping_methods != false && (
                  <div className=" ">
                    <ShippingMethods listing_details={listing_details} />
                  </div>
                )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_share_section !=
                false && (
                <div className=" ">
                  <ShareButtons
                    listing_details={listing_details}
                    router={router}
                  />
                </div>
              )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_qa_enabled != false && (
                <div className="     md:hidden" id={'qa'}>
                  <QNA_section listing_details={listing_details} />
                </div>
              )}
            </div>
          </div>

          <div className="  flex flex-col gap-page_default  ">
            {TYPE_CONSTANT.LISTINGS_CONFIGS?.similar_listing_card != false && (
              <div className=" w-full    ">
                <RelatedListings3 listing_details={listing_details} />
              </div>
            )}
            {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 &&
              TYPE_CONSTANT.LISTINGS_CONFIGS?.account_listings != false && (
                <div className="  w-full    ">
                  <AccountListings3
                    account_id={listing_details?.account_id}
                    account={listing_details?.account}
                  />
                </div>
              )}
          </div>

          {listing_details && (
            <div className="flex items-center justify-between flex-wrap ">
              <Breadcrumb
                lists={[
                  { name: General_text.general.home, link: '/' },
                  { name: Menubar_text.more.categories, link: '/lc' },
                  {
                    name: listing_details?.categories[0]?.name,
                    isHide: listing_details?.categories[0]?.name ? false : true,
                    link: `/lc/${
                      listing_details?.categories[0]?.slug
                        ? listing_details?.categories[0]?.slug
                        : `${listing_details?.categories[0]?.id}-${SlugChecker(
                            listing_details?.categories[0]?.name
                          )}`
                    }`,
                  },
                  {
                    name: listing_details?.title,
                    link: '',
                  },
                ]}
              />
              <ReportButton listing_details={listing_details} />
            </div>
          )}
        </>
      ) : (
        <NotFound message={Listing_text.product.no_product_found} />
      )}
    </div>
  );
};

export default CustomProductDetailsPageLayout;
