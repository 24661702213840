import OPPSetup from '../../../components/Opp/OPPSetup';
import WideSearchBox from '../../../components/SearchBox/WideSearchBox';
import WideSearchWithLocation from '../../../components/SearchBox/WideSearchWithLocation';
import DynamicListingFilter from '../../../components/Shared/DynamicListingFilter';
import AboutUs from '../../../components/StaticBlocks/AboutUs';
import AnimatedTextBlock from '../../../components/StaticBlocks/AnimatedTextBlock';
import CardBlock from '../../../components/StaticBlocks/CardBlock';
import CardBlockImageFirst from '../../../components/StaticBlocks/CardBlockImageFirst';
import CarouselCardBlock from '../../../components/StaticBlocks/CarouselCardBlock';
import CustomCta from '../../../components/StaticBlocks/CustomCta';
import DescriptionBlock from '../../../components/StaticBlocks/DescriptionBlock ';
import DividerBlock from '../../../components/StaticBlocks/DividerBlock';
import EmptyBlock from '../../../components/StaticBlocks/EmptyBlock';
import FilterByListingCities from '../../../components/StaticBlocks/FilterByListingCities';
import HeadingBlock from '../../../components/StaticBlocks/HeadingBlock';
import ImageBlock from '../../../components/StaticBlocks/ImageBlock';
import ImageDestinationBlock from '../../../components/StaticBlocks/ImageDestinationBlock';
import ListingCities from '../../../components/StaticBlocks/ListingCities';
import Paragraph from '../../../components/StaticBlocks/Paragraph';
import TitleDescriptionButton from '../../../components/StaticBlocks/TitleDescriptionButton';
import VideoBlock from '../../../components/StaticBlocks/VideoBlock';
import StripeSetup from '../../../components/Stripe/StripeSetup';
import BlankPageBanner from '../../../components/home/Banner/BlankPageBanner';
import Latest_pages from '../../../components/home/BlogsWidget/Latest_pages';
import Categories6 from '../../../components/home/Categories/Categories6';
import GridButtonTypeCategories from '../../../components/home/Categories/GridButtonTypeCategories';
import GridTabTypeCategories from '../../../components/home/Categories/GridTabTypeCategories';
import GridTabTypeCategoriesWithImage from '../../../components/home/Categories/GridTabTypeCategoriesWithImage';
import LatestCategories from '../../../components/home/Categories/LatestCategories';
import ListCategories from '../../../components/home/Categories/ListCategories';
import FeaturedCard2 from '../../../components/home/FeaturedCrad/FeaturedCard2';
import LeftImageFeaturedCard from '../../../components/home/FeaturedCrad/LeftImageFeaturedCard';
import LatestListings6 from '../../../components/home/LatestListings/LatestListings6';
import LatestListings7 from '../../../components/home/LatestListings/LatestListings7';
import StoresForFollow3 from '../../../components/home/Stores/StoresForFollow3';
import StoresListView from '../../../components/home/Stores/StoresListView';
import { Layer_text } from '../../../constant/Translation/LayerText';
import { sell_button_helper } from '../../../helpers/ListingDetails/SellButton';
import QuoteBlock from '../QuoteBlock';
import TrustedBlock from '../TrustedBlock';
import { common_blocks_cases } from './CommonBlockCase';

export const home_page_component_decide = ({
  order_number,
  block,
  block_data_key,
  fetch_by,
  data,
  isStatic,
  extra_data,
  styles,
  home_page_sections,
  AllListingFilter,
  setAllListingsFilter,
  AllAccountFilter,
  setAllAccountFilter,
  isEnabled = true,
}) => {
  const _styles = styles ?? data?.styles ?? {};
  const section = {
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
  };

  switch (block) {
    case 'category':
      return (
        <Categories6
          categories={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          styles={_styles}
          key={order_number}
        />
      );

    case 'latest_categories_block':
      return (
        <LatestCategories
          categories={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          styles={_styles}
          key={order_number}
        />
      );

    case 'account_collection':
      return (
        <StoresForFollow3
          account_collection={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data[0]
              : null
          }
          section_details={section}
          styles={_styles}
          key={order_number}
          extra_data={extra_data}
        />
      );
    case 'listing_collection':
      return (
        <LatestListings6
          product_collection={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data[0]
              : null
          }
          section_details={section}
          styles={_styles}
          key={order_number}
          extra_data={extra_data}
        />
      );

    case 'all_listings':
      return (
        <LatestListings7
          all_listings_data={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          section_details={{
            ...section,
            data: home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null,
          }}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
        />
      );

    case 'all_accounts':
      return (
        <StoresListView
          all_accounts_data={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          section_details={{
            ...section,
            data: home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null,
          }}
          styles={_styles}
          key={order_number}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
        />
      );

    case 'layer_pages':
      return (
        <Latest_pages
          pages={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          styles={_styles}
          key={order_number}
          extra_data={extra_data}
          header_title={Layer_text.layer.latest_pages}
        />
      );

    case 'layer_blogs':
      return (
        <Latest_pages
          pages={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          styles={_styles}
          key={order_number}
          extra_data={extra_data}
          header_title={Layer_text.layer.latest_blogs}
        />
      );

    case 'searchblock':
      return <WideSearchBox data={data} styles={_styles} key={order_number} />;
    case 'searchblock_with_location':
      return (
        <WideSearchWithLocation
          data={data}
          styles={_styles}
          key={order_number}
        />
      );
    case 'heading_title':
      return (
        data?.enabled && (
          <HeadingBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'listing_cities':
      return extra_data?.enabled &&
        extra_data?.is_connected_with_home_filter === true ? (
        <FilterByListingCities
          extra_data={extra_data}
          styles={_styles}
          key={order_number}
          listingsCities={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          setAllListingsFilter={setAllListingsFilter}
          AllListingFilter={AllListingFilter}
        />
      ) : (
        <ListingCities
          extra_data={extra_data}
          styles={_styles}
          key={order_number}
          listingsCities={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
        />
      );

    case 'description_block':
      return (
        data?.enabled && (
          <DescriptionBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'image_block':
      return (
        data?.enabled && (
          <ImageBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'image_with_destination_block':
      return (
        data?.enabled && (
          <ImageDestinationBlock
            data={data}
            styles={_styles}
            key={order_number}
          />
        )
      );
    case 'featured_card':
      return (
        data?.enabled && (
          <FeaturedCard2 data={data} styles={_styles} key={order_number} />
        )
      );
    case 'title_desc_button':
      return (
        data?.enabled && (
          <TitleDescriptionButton
            data={data}
            styles={_styles}
            key={order_number}
          />
        )
      );
    case 'promo_banner':
      return (
        data?.enabled && (
          <BlankPageBanner
            banners_data={
              home_page_sections
                ? home_page_sections.filter(
                    (item) => item.order_number === order_number
                  )[0]?.data
                : null
            }
            styles={_styles}
            key={order_number}
            extra_data={extra_data}
          />
        )
      );
    case 'video_featured':
      return (
        data?.enabled && (
          <VideoBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'quote_block':
      return (
        data?.enabled && (
          <QuoteBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'trusted_block':
      return (
        data?.enabled && (
          <TrustedBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'divider_block':
      return <DividerBlock styles={_styles} key={order_number} />;
    case 'card_block':
      return <CardBlock data={data} styles={_styles} key={order_number} />;
    case 'card_block_top_image':
      return (
        <CardBlockImageFirst data={data} styles={_styles} key={order_number} />
      );
    case 'carousel_card_block':
      return (
        <CarouselCardBlock data={data} styles={_styles} key={order_number} />
      );
    case 'about_us':
      return <AboutUs data={data} styles={_styles} key={order_number} />;
    case 'left_image_featured_card':
      return (
        <LeftImageFeaturedCard
          data={data}
          styles={_styles}
          key={order_number}
        />
      );
    case 'list_categories':
      return (
        <ListCategories
          categories={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          setAllListingsFilter={setAllListingsFilter}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
          fetch_by={fetch_by}
        />
      );
    case 'grid_categories':
      return (
        <GridButtonTypeCategories
          categories={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          setAllListingsFilter={setAllListingsFilter}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
          fetch_by={fetch_by}
        />
      );
    case 'grid_tab_categories':
      return (
        <GridTabTypeCategories
          categories={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          setAllListingsFilter={setAllListingsFilter}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
          fetch_by={fetch_by}
        />
      );
    case 'grid_tab_categories_with_image':
      return (
        <GridTabTypeCategoriesWithImage
          categories={
            home_page_sections
              ? home_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          setAllListingsFilter={setAllListingsFilter}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
          fetch_by={fetch_by}
        />
      );
    case 'paragraph':
      return <Paragraph data={data} styles={styles} />;
    case 'cta':
      return <CustomCta data={data} styles={styles} />;
    case 'dynamic_listings_filter':
      return (
        <DynamicListingFilter
          data={data}
          styles={styles}
          setAllListingsFilter={setAllListingsFilter}
          AllListingFilter={AllListingFilter}
        />
      );
    case 'empty_block':
      return <EmptyBlock data={data} styles={styles} />;
    case 'opp_setup_button':
      return isEnabled && <OPPSetup data={data} styles={styles} />;
    case 'stripe_setup_button':
      return isEnabled && <StripeSetup data={data} styles={styles} />;
    case 'animated_paragraph':
      return <AnimatedTextBlock data={data} styles={styles} />;

    default:
      return common_blocks_cases({ section });
  }
};
