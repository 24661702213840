export var Add_store_text = {
  addstore: {
    image_size_alert:
      'Image size is too large. Please select an image less than or equal to 1 MB.',
    shipping_charge: 'Shipping charge',
    select: 'Select',
    add_image: 'Add Image',
    slug: 'Slug',
    type: 'Type...',
    multi_write_value_info: 'Using a comma, you can enter several values.',
    upload_attachment: 'Upload Attachment',
    select_date_time: 'Select date & time',
    upload_file: 'Upload file',
    upload_json_file: 'Upload Json file',
    alert_price: 'Price is required',
    add_image: 'Add Image',
    set_date: 'Select date & time',
    alert_store_title: 'Add account title',
    alert_store_address: 'Add account address',
    alert_attribute_required: '{value} is required',
    alert_max_image_upload: ' Max. {value} photos per Listing',
    alert_address_not_found: 'Address Not found',
    alert_pickup_address_not_found: 'Set pickup address',
    alert_shipping_method_not_found: 'Select shipping method',
    account_waiting_for_approval:
      'Your account is waiting for approval. You can start posting once it is approved.',
    add_store: 'Add Store',
    add_your_store_photo: 'Add your store photo',
    added_success_message: 'Store added successfully',
    address: 'Address',
    alert_message_account_add:
      'Something went wrong while {value} your account.Try again later',
    alert_message_category_list_fetch_failed:
      'Something went wrong, while fetching category',
    alert_message_choose_category: 'Please choose Category',
    alert_message_choose_shipment: 'Please choose preferred shipment',
    alert_message_store_link_alias: 'Name already taken',
    alert_please_select_address: 'Please select address',
    category: 'Category',
    create: 'Create',
    creating: 'creating',
    donot_have_store: 'You don’t have an account to post listings',
    fieldvalidation: 'Fill {value} before continuing',
    filedownload_failed: 'File download failed',
    fileupload_browse: 'Browse',
    fileupload_completed: 'Completed',
    fileupload_failed: 'Unable to upload, Techinical issue',
    fileupload_limit_message: 'Upload file document limit of 5 MB',
    header_title: 'Create your Store',
    imagefield: 'Image',
    ok: 'OK',
    preferred_shipment: 'Preferred Shipment',
    store_description: 'Store Description',
    store_name: 'Store Name',
    store_url: 'Store URL',
    store_url_unique_error: 'Store URL already taken',
    update_store_title: 'Update Your Store',
    update_success: 'Updated Successfully',
    update_success_message: 'Store updated successfully',
    updating: 'updating',
    use_comma_to_add_value: 'Use commas to add values',
    addstore_edit_store: 'Edit Store',
    store_created_successfully: 'Store has been created successfully',
    store_updated_successfully: 'Store has been updated successfully',
    description: 'Description',
    select_single_value: 'Select Single Value',
    select_multi_value: 'Select Multi Value',
  },
};
