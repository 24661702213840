/* eslint-disable react/prop-types */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import {
  applyCouponToCart,
  cartList,
  deleteCouponFromCart,
} from '../../../store/feature/cartSlice';
import ItemQuantity from './ItemQuantity';
import OrderItems from './OrderItems';

const OrderSummary = ({ cart, cart_details, shippingMethod }) => {
  const [coupon_code, setCouponCode] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (cart?.coupon?.code) {
      setCouponCode(cart?.coupon?.code);
    }
  }, [cart]);

  const coupon_click = (cart_id) => {
    if (coupon_code) {
      dispatch(
        cart?.coupon?.code
          ? deleteCouponFromCart({
              data: {
                cart: {
                  id: cart_id,
                },
              },
            })
          : applyCouponToCart({
              data: {
                cart: {
                  id: cart_id,
                  coupon: {
                    code: coupon_code,
                  },
                },
              },
            })
      ).then((res) => {
        if (!res.payload.code) {
          const cart_list_params = {};
          if (shippingMethod !== null && shippingMethod) {
            cart_list_params.bodyParam = {
              shipping_method_id: shippingMethod?.id,
            };
          }
          dispatch(cartList(cart_list_params));
        }
      });
    }
  };

  return cart_details ? (
    <div className=" w-full min-h-[200px] bg-[#FEFEFE] rounded-card   py-6 px-6 ">
      <p className="text-primary text-xl leading-6 font-medium  ">
        {Checkout_text.cart.order_summery}
      </p>
      {/* <div className="mt-5">
        <OrderItems cart_details={cart_details} />
      </div> */}
      {/* Coupon */}
      {TYPE_CONSTANT.ORDERS_CONFIGS.enable_coupon && (
        <div className="flex items-center justify-center mt-6 gap-2">
          <input
            type="text"
            value={coupon_code}
            placeholder={Checkout_text.cart.coupon_value}
            className="
                  flex-grow
                    block
                    w-full
                     p-0
                     px-2
                     h-10
                    rounded-input
                    border
                    border-gray-300
                    focus:border-primary  
                    shadow-sm
                   "
            disabled={cart?.coupon?.code ? true : false}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <button
            className={[
              'flex-none btn-primary-outline px-2 h-10',
              coupon_code == '' ? 'cursor-not-allowed' : 'cursor-pointer',
            ].join(' ')}
            disabled={coupon_code == '' ? true : false}
            onClick={() => coupon_code !== '' && coupon_click(cart.id)}
          >
            {cart?.coupon?.code
              ? Checkout_text.cart.remove_coupon
              : Checkout_text.cart.apply_coupon}
          </button>
        </div>
      )}
      {cart?.coupon?.message && (
        <p className="text-sm font-medium   text-success  rounded card py-2   mt-1   ">
          {cart?.coupon?.message}
        </p>
      )}

      {/* Price Details */}
      <div className="mt-6">
        {/* */}
        {cart.pricing_items.length > 0 ? (
          <>
            {cart.pricing_items.map((pricing_item) => {
              if (pricing_item?.display) {
                return (
                  <p
                    className={[
                      ' flex justify-between items-center mb-2 text-base leading-6 font-semibold text-default_gray',
                    ].join(' ')}
                  >
                    <span>{pricing_item.name}</span>
                    <div className="flex items-center gap-2">
                      {pricing_item?.regular && (
                        <span className=" flex flex-wrap items-center    line-through">
                          <span
                            className={[
                              'text-base leading-6 font-semibold text-gray-600 ',
                            ].join(' ')}
                          >
                            {pricing_item?.regular?.formatted}
                          </span>
                        </span>
                      )}
                      <span className=" flex flex-wrap items-center">
                        <span
                          className={[
                            'text-base leading-6 font-semibold ',
                            pricing_item?.type === 'sub_total'
                              ? ' text-black'
                              : pricing_item?.type === 'customer_coupon'
                              ? 'text-success'
                              : 'text-gray-600 ',
                          ].join(' ')}
                        >
                          {pricing_item?.buying?.formatted}
                        </span>
                      </span>
                    </div>
                  </p>
                );
              }
            })}
            <p
              className={[
                ' flex justify-between items-center  text-xl leading-6 font-semibold text-black',
              ].join(' ')}
            >
              <span>{Checkout_text.cart.grand_total}</span>{' '}
              <span className=" flex flex-wrap items-center">
                <span className="text-base leading-6 font-semibold text-black">
                  {cart.grand_total?.formatted}
                </span>
              </span>
            </p>
          </>
        ) : (
          <>
            <p className="text-base leading-6 font-semibold text-default_gray flex justify-between items-center mb-2">
              <span>{Checkout_text.cart.total}</span>{' '}
              <span className=" flex flex-wrap items-center">
                <span className="text-base leading-6 font-semibold text-gray-600">
                  {cart.list_total?.formatted}
                </span>
              </span>
            </p>
            <p className="text-base leading-6 font-semibold text-default_gray flex justify-between items-center mb-2">
              <span>{Checkout_text.cart.shipping}</span>{' '}
              <span className=" flex flex-wrap items-center">
                <span className="text-base leading-6 font-semibold text-gray-600">
                  {cart.shipping_total?.formatted}
                </span>
              </span>
            </p>
            <p className="text-xl leading-6 font-semibold text-black flex justify-between items-center">
              <span>{Checkout_text.cart.subtotal}</span>{' '}
              <span className=" flex flex-wrap items-center">
                <span className="text-xl leading-6 font-semibold text-black">
                  {cart.grand_total?.formatted}
                </span>
              </span>
            </p>{' '}
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="  border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
