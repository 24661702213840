/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import tradly from 'tradly';
import { authSelector } from '../../../store/feature/authSlice';
import { myAccountListings } from '../../../store/feature/storeSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import { Switch } from '@headlessui/react';

import {
  changeDateFormat,
  getThumbnailImage,
} from '../../Shared/Constant/Constant';
import Warning from '../../Shared/PopUp/Warning';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import { configsSelector } from '../../../store/feature/configsSlice';
import axios from 'axios';
import { Listing_text } from '../../../constant/Translation/Listing';
import ListingCardButtonType from '../../Shared/Cards/ListingCardButtonType';
import ListTypeCard from '../../Shared/Cards/ListingCardSections/ListTypeCard';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import DynamicListingCard from '../../Shared/Cards/DynamicListingCard';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { responsive_design_styles } from '../../../utils/ResponsiveDesign';
import { useScreenSize } from '../../../hooks/useScreenSize';

const StoreListings = ({ my_store_listings, my_stores }) => {
  const { width, height } = useScreenSize();

  //
  const [MARKETPLACE_MODULES, setMARKETPLACE_MODULES] = useState(
    localStorage.getItem('MARKETPLACE_MODULES')
  );

  const [showWarning, setShowWarning] = useState(false);
  const [warning_message, setWarning_message] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const dispatch = useDispatch();
  const router = useRouter();

  // deleteListing
  const deleteListing = (id) => {
    setIsloading(true);
    axios
      .post('/api/l/delete_listing', { id })
      .then((res) => {
        dispatch(
          myAccountListings({
            prams: {
              page: router.query?.page ? router.query?.page : 1,
              account_id: my_stores[0].id,
            },
          })
        );
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const closePopUP = () => {
    setShowWarning(false);
    setWarning_message('');
  };

  // change listing status
  const change_active = (item) => {
    setIsloading(true);
    axios
      .post('/api/l/edit_listing', {
        productId: item.id,
        data: { listing: { active: !item.active } },
      })
      .then((res) => {
        dispatch(
          myAccountListings({
            prams: {
              page: router.query?.page ? router.query?.page : 1,
              account_id: my_stores[0].id,
            },
          })
        );
        setIsloading(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  // duplication
  const duplicateListing = (item) => {
    setIsloading(true);
    axios
      .post('/api/l/edit_listing', {
        productId: item.id,
        data: { listing: { active: !item.active } },
      })
      .then((res) => {
        dispatch(
          myAccountListings({
            prams: {
              page: router.query?.page ? router.query?.page : 1,
              account_id: my_stores[0].id,
            },
          })
        );
        setIsloading(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };
  //
  const listing_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.listing_card;
  const dynamic_card = TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_listing_card;
  const list_styles = responsive_design_styles({
    width,
    parent_group: dynamic_card?.styles,
    child_group_name: 'list_styles',
  });

  return (
    <>
      {' '}
      {isLoading && <CustomLoading />}
      {showWarning && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showWarning && (setShowWarning(false), setWarning_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <Warning message={warning_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {/* Listings list */}
      <div
        className={
          listing_card_styles?.card_type?.type !== 'custom_card'
            ? 'grid  grid-cols-1   xxs:grid-cols-[repeat(auto-fill,minmax(calc(var(--listingCardMinWidth)*var(--listingCardPerRow)/var(--listingCardPerRow)),1fr))] gap-5 justify-center'
            : ' '
        }
        style={
          listing_card_styles?.card_type?.type == 'custom_card'
            ? {
                display: 'grid',
                gridTemplateColumns: `repeat(${list_styles?.gridTemplateColumns}, minmax(0, 1fr))`,
                gap: list_styles?.gap,
              }
            : {}
        }
      >
        {my_store_listings?.map((item) => (
          <div key={item?.id} className=" w-full relative">
            <DynamicListingCard
              product={item}
              MARKETPLACE_MODULES={MARKETPLACE_MODULES}
              is_hide_like_btn={true}
            />

            {/* Edit button */}
            <div
              className=" h-[30px] w-[30px]  absolute ltr:right-0 rtl:left-0 top-0 cursor-pointer  [z-100] justify-center items-center  bg-white ltr:rounded-bl-md rt:rounded-br-md"
              onClick={() => {
                if (item.active) {
                  router.push({
                    pathname: '/a/edit-listing',
                    query: {
                      product_id: item.id,
                      account_id: my_stores[0].id,
                    },
                  });
                } else {
                  setShowWarning(true);
                  setWarning_message(Listing_text.product.waiting_for_approval);
                }
              }}
            >
              <span className=" w-full h-full flex justify-center items-center text-primary">
                {AllIcons.edit_icon}
              </span>
            </div>

            {/* delete button */}
            <div
              className=" h-[30px] w-[30px]  absolute ltr:right-0 rtl:left-0 top-0 mt-[35px] cursor-pointer  [z-100] justify-center items-center  bg-white ltr:rounded-bl-md rt:rounded-br-md"
              onClick={() => deleteListing(item.id)}
            >
              <span className=" w-full h-full flex justify-center items-center text-primary">
                {AllIcons.delete_icon}
              </span>
            </div>
            {/* duplicate button */}
            {/* <div
              className=" h-[30px] w-[30px]  absolute ltr:right-0 rtl:left-0 top-0 mt-[75px] cursor-pointer  [z-100] flex text-primary justify-center items-center  bg-white ltr:rounded-bl-md rt:rounded-br-md"
              onClick={() => duplicateListing(item)}
            >
              {AllIcons.copy_icon}
            </div> */}

            {/* alert */}
            {!item.active && item.status === 1 && (
              <div className=" h-8 w-[110px]  absolute ltr:left-0 rtl:right:0 top-0 cursor-pointer  [z-100] justify-center items-center   bg-yellow-400   rounded-br-lg">
                <p className=" h-full w-full flex justify-center items-center  text-gray-900 text-center text-sm font-medium">
                  {Listing_text.product.under_review}
                </p>
              </div>
            )}

            {/* Switch */}
            {item.status != 1 && item.status != 3 && (
              <div className=" min-h-4  w-[150px]     absolute ltr:left-0 rtl:right:0 top-0 cursor-pointer   grid grid-cols-2 justify-center items-center      rounded-b-lg overflow-hidden">
                <Switch
                  checked={item.active}
                  onChange={() => {
                    change_active(item);
                  }}
                  className={`${item.active ? 'bg-yellow-400' : 'bg-yellow-500'}
          relative inline-flex flex-shrink-0 h-[30px] min-w-[160px] border-2 border-transparent  rounded-br-lg cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      item.active
                        ? 'ltr:translate-x-9 rtl:-translate-x-9'
                        : 'ltr:translate-x-0 rtl:-translate-x-0'
                    }
           pointer-events-none inline-block h-[26px] min-w-[26px]  rounded-br-lg  px-2 bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                  >
                    {item.active
                      ? Listing_text.product.deactivate
                      : Listing_text.product.activate}
                  </span>
                </Switch>
              </div>
            )}

            {/* rejected button */}
            {item.status == 3 && (
              <div className=" absolute top-0 left-0  bg-error px-6 py-1 text-white rounded-br-md">
                {Listing_text.product.rejected}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default StoreListings;
