import axios from 'axios';
import { Router, useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import AccountProfile from '../../components/AccountDetails/AccountProfile/AccountProfile';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import ErrorPopup from '../../components/Shared/PopUp/ErrorPopup';
import AccountTab from '../../components/AccountDetails/AccountTabs/AccountTab';
import { useDispatch } from 'react-redux';
import {
  accountListings,
  getStoreReviews,
} from '../../store/feature/storeSlice';
import NotFound from '../../components/Shared/Default UI/NotFound';
import { Account_text } from '../../constant/Translation/Account';
import AccountReportButton from '../../components/AccountDetails/account-report/AccountReportButton';
import SuccessPopup from '../../components/Shared/PopUp/SuccessPopup';
import { General_text } from '../../constant/Translation/General';

const AccountDetailsPageLayout = () => {
  const [account_details, setAccount_details] = useState({});
  const [isDataLoading, setIsDataLoading] = useState();

  const router = useRouter();
  const dispatch = useDispatch();

  // =>>>>>Error state
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // <<<<<<<<==

  // =>>>>>success state
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  // <<<<<<<<==

  useEffect(() => {
    const path = router.query?.id
      ? `/api/a/${router.query.slug}?id=${router.query?.id}`
      : `/api/a/${router.query.slug}`;

    axios
      .get(path)
      .then((res) => {
        setAccount_details(res.data.account);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMessage(error.response?.data?.message);
        setAccount_details(null);
      });
  }, [router.query]);

  useEffect(() => {
    if (account_details?.id) {
      dispatch(
        accountListings({
          prams: {
            ...router.query,
            account_id: account_details.id,
            status: 2,
          },
        })
      );
      dispatch(
        getStoreReviews({
          params: {
            type: 'accounts',
            id: account_details.id,
            page: 1,
          },
        })
      );
    }
  }, [account_details]);

  return (
    <div>
      {isDataLoading && <CustomLoading />}

      {/* Error Component>>>>> */}
      <ErrorPopup
        showError={showError}
        setShowError={setShowError}
        message={errorMessage}
      />
      {/* Success Component>>>>> */}
      <SuccessPopup
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        message={successMessage}
      />

      {/* <<<<Error Component */}
      {account_details !== null ? (
        Object.keys(account_details).length > 0 && (
          <div>
            <div>
              <AccountProfile
                account_details={account_details}
                setAccount_details={setAccount_details}
                setIsDataLoading={setIsDataLoading}
              />
            </div>
            {/* <div>
            <AccountDescription account_details={account_details} />
          </div> */}
            <AccountTab
              account_details={account_details}
              setAccount_details={setAccount_details}
              setIsDataLoading={setIsDataLoading}
            />
          </div>
        )
      ) : (
        <NotFound message={Account_text.storedetail.store_not_available} />
      )}

      {account_details && (
        <div className="mt-5 flex items-center justify-between flex-wrap">
          <Breadcrumb
            lists={[
              { name: General_text.general.home, link: '/' },
              {
                name: account_details?.name,
                link: '',
              },
            ]}
          />
          <AccountReportButton
            account_details={account_details}
            setShowError={setShowError}
            setErrorMessage={setErrorMessage}
            setShowSuccess={setShowSuccess}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      )}
    </div>
  );
};

export default AccountDetailsPageLayout;
