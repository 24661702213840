/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { slug as SlugChecker } from 'github-slugger';
import { dateToEpochInSeconds } from '../../Shared/Constant/Constant';
import { replace_special_characters } from '../../../utils/ReplaceSpecialChar';

export const edit_product_click = async (
  imagePath,
  fullFile,
  title,
  slug,
  meta_title,
  meta_description,
  meta_keyword,
  description,
  price,
  shippingCharge,
  offerPercent,
  quantity,
  coordinates,
  full_location,
  selectedCategory,
  attributeData,
  currency,
  setShowError,
  setError_message,
  dispatch,
  router,
  listing_configs,
  auth_key,
  accountId,
  productId,
  setEditProductLoading,
  setShowSuccessMessage,
  attributes,
  order_type,
  goal_price,
  startAt,
  endAt,
  weight_uom,
  weight_value,
  seller_protection_fee_enabled,
  tags,
  height_value,
  width_value,
  size_uom,
  length_value,
  time_zone,
  tax_ids,
  add_listing_current_blocks
) => {
  setEditProductLoading(true);

  if (imagePath.length === 0 || imagePath.length === null) {
    if (fullFile.length === 0) {
      setShowError(true);
      setError_message(Add_product_text.addproduct.add_image);
      setEditProductLoading(false);
      return false;
    }
  }
  if (title === '') {
    setShowError(true);
    setError_message(Add_product_text.addproduct.alert_listing_title);
    setEditProductLoading(false);
    return false;
  }
  // if (description === '') {
  //   setShowError(true);
  //   setError_message('Description is required');
  //   setEditProductLoading(false);
  //   return false;
  // }
  if (
    price === '' &&
    order_type !== 'donation' &&
    order_type !== 'information_listing' &&
    order_type !== 'video_listing'
  ) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.alert_price);
    setEditProductLoading(false);
    return false;
  }
  if (
    price < parseInt(listing_configs?.listing_min_price) &&
    order_type !== 'donation' &&
    order_type !== 'information_listing' &&
    order_type !== 'video_listing'
  ) {
    setShowError(true);
    setError_message(
      dynamic_translation_set(
        Add_product_text.addproduct.min_price_validation,
        parseInt(listing_configs?.listing_min_price)
      )
    );
    setEditProductLoading(false);
    return false;
  }
  if (
    listing_configs.listing_address_enabled &&
    order_type !== 'information_listing' &&
    order_type !== 'video_listing'
  ) {
    if (coordinates === null) {
      setShowError(true);
      setError_message(Add_product_text.addproduct.alert_listing_address);
      setEditProductLoading(false);
      return false;
    }
  }
  if (
    listing_configs.enable_stock &&
    order_type !== 'information_listing' &&
    order_type !== 'video_listing'
  ) {
    if (quantity === null || quantity === '') {
      setShowError(true);
      setError_message(Add_product_text.addproduct.max_quantity_validation);
      setEditProductLoading(false);
      return false;
    }
  }
  if (order_type === 'donation' && (startAt === null || endAt === null)) {
    setShowError(true);
    setError_message(
      Add_product_text.addproduct.alert_start_and_date_not_found
    );
    setEditProductLoading(false);
    return false;
  }
  if (order_type === 'donation' && (startAt !== null || endAt !== null)) {
    startAt = startAt ? dateToEpochInSeconds(startAt) : null;
    endAt = endAt ? dateToEpochInSeconds(endAt) : null;
  }
  if (listing_configs.listing_enable_weight && order_type == 'listings') {
    if (
      weight_value === null ||
      weight_uom === null ||
      weight_value === undefined ||
      weight_uom === undefined
    ) {
      setShowError(true);
      setError_message(Add_product_text.addproduct.weight_alert);
      setEditProductLoading(false);
      return false;
    }
  }

  if (listing_configs.listing_enable_dimensions && order_type == 'listings') {
    if (
      height_value === null ||
      size_uom === null ||
      width_value === null ||
      length_value === null ||
      height_value === undefined ||
      size_uom === undefined ||
      width_value === undefined ||
      length_value === undefined
    ) {
      setShowError(true);
      setError_message(Add_product_text.addproduct.dimension_alert);
      setEditProductLoading(false);
      return false;
    }
  }

  //* Tax checking
  if (
    add_listing_current_blocks?.includes('listing_taxes') &&
    tax_ids?.length <= 0
  ) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.listing_tax);
    setEditProductLoading(false);
    return false;
  }

  //
  if (order_type === 'events' && time_zone === null) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.time_zone_alert);
    setEditProductLoading(false);
    return false;
  }

  if (selectedCategory === null) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.alert_message_choose_category);
    setEditProductLoading(false);
    return false;
  }

  if (attributes?.length > 0) {
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      if (!attribute.optional) {
        if (
          !attributeData?.filter((at_data) => at_data.id === attribute.id)
            ?.length > 0 ||
          attributeData?.length === 0
        ) {
          setShowError(true);
          setError_message(
            dynamic_translation_set(
              Add_product_text.addproduct.alert_attribute_required,
              attribute.name
            )
          );
          setEditProductLoading(false);
          return false;
          break;
        }
      }
    }
  }

  // Get Image url function:
  const images_uri = async (all_images) => {
    let all_images_uri = [];
    let upload_images = [];
    let upload_images_files = [];
    for (let i = 0; i < all_images.length; i++) {
      const element = all_images[i];

      // Set in state
      if (element.full_file === null) {
        all_images_uri.push(element.path);
      } else {
        upload_images.push({ name: element.name, type: element.type });
        upload_images_files.push(element.full_file);
      }

      // Upload images checking
      if (all_images.length === i + 1 && upload_images.length > 0) {
        try {
          const response = await axios.post('/api/generateS3ImageURL', {
            data: {
              files: upload_images,
            },
          });

          const responseFiles = response.data.result;
          for (let index = 0; index < responseFiles.length; index++) {
            const path = responseFiles[index].signedUrl;
            const fileURI = responseFiles[index].fileUri;

            try {
              const res = await fetch(path, {
                method: 'PUT',
                headers: {
                  ContentType: upload_images[index].type,
                },
                body: upload_images_files[index],
              });

              if (res.ok) {
                all_images_uri.push(fileURI);
              }
            } catch (error) {
              setShowError(true);
              setError_message(error?.response?.data?.message);
              setEditProductLoading(false);
            }
          }
        } catch (error) {
          setShowError(true);
          setError_message(error?.response?.data?.message);
          setEditProductLoading(false);
        }
      }

      // return all images url
      if (all_images_uri.length == all_images.length) {
        return all_images_uri;
      }
    }
  };

  // GET IMAGE URL of Attributes:
  const upload_attributes_files = async (all_attributes) => {
    let all_updated_attributes = [];
    let upload_attributes_files = [];
    let upload_attributes_full_files = [];

    for (let i = 0; i < all_attributes.length; i++) {
      const element = all_attributes[i];

      // Set in state
      if (element.uploadFile) {
        upload_attributes_files.push({
          name: element.values[0].name,
          type: element.values[0].type,
          id: element.id,
        });
        upload_attributes_full_files.push(element.values[0]);
      } else {
        all_updated_attributes.push(element);
      }

      // Upload file checking
      if (
        all_attributes.length === i + 1 &&
        upload_attributes_files.length > 0
      ) {
        try {
          const response = await axios.post('/api/generateS3ImageURL', {
            data: {
              files: upload_attributes_files.map((file) => {
                return { name: file.name, type: file.type };
              }),
            },
          });

          const responseFiles = response.data.result;
          for (let index = 0; index < responseFiles.length; index++) {
            const path = responseFiles[index].signedUrl;
            const fileURI = responseFiles[index].fileUri;

            try {
              const res = await fetch(path, {
                method: 'PUT',
                headers: {
                  ContentType: upload_attributes_files[index].type,
                },
                body: upload_attributes_full_files[index],
              });

              if (res.ok) {
                all_updated_attributes.push({
                  values: [fileURI],
                  id: upload_attributes_files[index].id,
                });
              }
            } catch (error) {
              setShowError(true);
              setError_message(error?.response?.data?.message);
              setEditProductLoading(false);
            }
          }
        } catch (error) {
          setShowError(true);
          setError_message(error?.response?.data?.message);
          setEditProductLoading(false);
        }
      }

      // return all images url
      if (all_updated_attributes.length == all_attributes.length) {
        return all_updated_attributes;
      }
    }
  };

  try {
    const images_response = await images_uri(fullFile);
    if (images_response?.length) {
      if (attributeData !== null && attributeData?.length !== 0) {
        const check = attributeData.find((attr) => attr.uploadFile);
        if (check === undefined) {
          const listingData = {
            list_price: price,
            account_id: accountId,
            currency_id: currency,
            attributes: attributeData,
            title: title,
            offer_percent: offerPercent,
            images: images_response,
            category_id: [selectedCategory],
            type: 'listings',
            order_type: order_type,
          };

          if (Number(goal_price) > 0) {
            listingData['goal_price'] = Number(goal_price);
          }
          if (order_type === 'events' && time_zone) {
            listingData['time_zone'] = time_zone;
          }
          if (order_type === 'donation' && startAt) {
            listingData['start_at'] = startAt;
          }
          if (order_type === 'donation' && endAt) {
            listingData['end_at'] = endAt;
          }
          if (listing_configs.listing_address_enabled) {
            listingData['coordinates'] = coordinates;
          }
          if (full_location != null || full_location != undefined) {
            listingData['location'] = full_location;
          }
          if (listing_configs.enable_stock) {
            listingData['stock'] = quantity;
          }
          if (tax_ids?.length > 0) {
            listingData['tax_ids'] = tax_ids;
          }
          if (listing_configs.show_shipping_charges) {
            listingData['shipping_charges'] = shippingCharge;
          }
          if (
            listing_configs.listing_enable_weight &&
            order_type == 'listings'
          ) {
            listingData['weight_uom'] = weight_uom;
            listingData['weight_value'] = weight_value;
          }
          if (
            listing_configs.listing_enable_dimensions &&
            order_type == 'listings'
          ) {
            listingData['size_uom'] = size_uom;
            listingData['width'] = Number(width_value);
            listingData['height'] = Number(height_value);
            listingData['length'] = Number(length_value);
          }
          if (
            listing_configs.listing_seller_protection_fee_enabled &&
            order_type == 'listings'
          ) {
            listingData['seller_protection_fee_enabled'] =
              seller_protection_fee_enabled;
          }
          if (!description?.replace(/\s/g, '').length <= 0) {
            listingData['description'] = description;
          }
          if (!tags?.replace(/\s/g, '').length <= 0) {
            listingData['tags'] = tags.split(',');
          }
          if (!meta_title?.replace(/\s/g, '').length <= 0) {
            listingData['meta_title'] = meta_title;
          }
          if (!meta_keyword?.replace(/\s/g, '').length <= 0) {
            listingData['meta_keyword'] = meta_keyword;
          }
          if (!meta_description?.replace(/\s/g, '').length <= 0) {
            listingData['meta_description'] = meta_description;
          }
          if (!slug?.replace(/\s/g, '').length <= 0) {
            listingData['slug'] = SlugChecker(slug);
          } else {
            listingData['slug'] = SlugChecker(
              replace_special_characters(title)
            );
          }

          // post data

          axios
            .post('/api/l/edit_listing', {
              productId,
              data: { listing: listingData },
            })
            .then((res) => {
              push_data_layer({
                event: 'edit_listing',
                data: {
                  title: title,
                  price: price,
                },
              });
              setEditProductLoading(false);
              setShowSuccessMessage(true);
            })
            .catch((error) => {
              setShowError(true);
              setError_message(error.response.data.message);
              setEditProductLoading(false);
            });
        } else {
          try {
            const updated_attributes = await upload_attributes_files(
              attributeData
            );
            if (updated_attributes) {
              const listingData = {
                list_price: price,
                account_id: accountId,
                currency_id: currency,
                attributes: updated_attributes,
                title: title,
                offer_percent: offerPercent,
                images: images_response,
                category_id: [selectedCategory],
                type: 'listings',
                order_type: order_type,
              };

              if (Number(goal_price) > 0) {
                listingData['goal_price'] = Number(goal_price);
              }
              if (order_type === 'events' && time_zone) {
                listingData['time_zone'] = time_zone;
              }
              if (order_type === 'donation' && startAt) {
                listingData['start_at'] = startAt;
              }
              if (order_type === 'donation' && endAt) {
                listingData['end_at'] = endAt;
              }
              if (listing_configs.listing_address_enabled) {
                listingData['coordinates'] = coordinates;
              }
              if (full_location != null || full_location != undefined) {
                listingData['location'] = full_location;
              }
              if (listing_configs.enable_stock) {
                listingData['stock'] = quantity;
              }
              if (tax_ids?.length > 0) {
                listingData['tax_ids'] = tax_ids;
              }
              if (listing_configs.show_shipping_charges) {
                listingData['shipping_charges'] = shippingCharge;
              }
              if (
                listing_configs.listing_enable_weight &&
                order_type == 'listings'
              ) {
                listingData['weight_uom'] = weight_uom;
                listingData['weight_value'] = weight_value;
              }
              if (
                listing_configs.listing_enable_dimensions &&
                order_type == 'listings'
              ) {
                listingData['size_uom'] = size_uom;
                listingData['width'] = Number(width_value);
                listingData['height'] = Number(height_value);
                listingData['length'] = Number(length_value);
              }
              if (
                listing_configs.listing_seller_protection_fee_enabled &&
                order_type == 'listings'
              ) {
                listingData['seller_protection_fee_enabled'] =
                  seller_protection_fee_enabled;
              }
              if (!description?.replace(/\s/g, '').length <= 0) {
                listingData['description'] = description;
              }
              if (!tags?.replace(/\s/g, '').length <= 0) {
                listingData['tags'] = tags.split(',');
              }
              if (!meta_title?.replace(/\s/g, '').length <= 0) {
                listingData['meta_title'] = meta_title;
              }
              if (!meta_keyword?.replace(/\s/g, '').length <= 0) {
                listingData['meta_keyword'] = meta_keyword;
              }
              if (!meta_description?.replace(/\s/g, '').length <= 0) {
                listingData['meta_description'] = meta_description;
              }
              if (!slug?.replace(/\s/g, '').length <= 0) {
                listingData['slug'] = SlugChecker(slug);
              } else {
                listingData['slug'] = SlugChecker(
                  replace_special_characters(title)
                );
              }

              // post listing data
              axios
                .post('/api/l/edit_listing', {
                  productId,
                  data: { listing: listingData },
                })
                .then((res) => {
                  push_data_layer({
                    event: 'edit_listing',
                    data: {
                      title: title,
                      price: price,
                    },
                  });
                  setEditProductLoading(false);
                  setShowSuccessMessage(true);
                })
                .catch((error) => {
                  setShowError(true);
                  setError_message(error?.response?.data?.message);
                  setEditProductLoading(false);
                });
            }
          } catch (error) {
            setShowError(true);
            setError_message(error?.response?.data?.message);
            setEditProductLoading(false);
          }
        }
      } else {
        const listingData = {
          list_price: price,
          account_id: accountId,
          currency_id: currency,
          title: title,
          offer_percent: offerPercent,
          images: images_response,
          category_id: [selectedCategory],
          type: 'listings',
          order_type: order_type,
        };

        if (Number(goal_price) > 0) {
          listingData['goal_price'] = Number(goal_price);
        }
        if (order_type === 'events' && time_zone) {
          listingData['time_zone'] = time_zone;
        }
        if (order_type === 'donation' && startAt) {
          listingData['start_at'] = startAt;
        }
        if (order_type === 'donation' && endAt) {
          listingData['end_at'] = endAt;
        }
        if (listing_configs.listing_address_enabled) {
          listingData['coordinates'] = coordinates;
        }
        if (full_location != null || full_location != undefined) {
          listingData['location'] = full_location;
        }
        if (listing_configs.enable_stock) {
          listingData['stock'] = quantity;
        }
        if (tax_ids?.length > 0) {
          listingData['tax_ids'] = tax_ids;
        }
        if (listing_configs.show_shipping_charges) {
          listingData['shipping_charges'] = shippingCharge;
        }
        if (listing_configs.listing_enable_weight && order_type == 'listings') {
          listingData['weight_uom'] = weight_uom;
          listingData['weight_value'] = weight_value;
        }
        if (
          listing_configs.listing_enable_dimensions &&
          order_type == 'listings'
        ) {
          listingData['size_uom'] = size_uom;
          listingData['width'] = Number(width_value);
          listingData['height'] = Number(height_value);
          listingData['length'] = Number(length_value);
        }
        if (
          listing_configs.listing_seller_protection_fee_enabled &&
          order_type == 'listings'
        ) {
          listingData['seller_protection_fee_enabled'] =
            seller_protection_fee_enabled;
        }
        if (!description?.replace(/\s/g, '').length <= 0) {
          listingData['description'] = description;
        }
        if (!tags?.replace(/\s/g, '').length <= 0) {
          listingData['tags'] = tags.split(',');
        }
        if (!meta_title?.replace(/\s/g, '').length <= 0) {
          listingData['meta_title'] = meta_title;
        }
        if (!meta_keyword?.replace(/\s/g, '').length <= 0) {
          listingData['meta_keyword'] = meta_keyword;
        }
        if (!meta_description?.replace(/\s/g, '').length <= 0) {
          listingData['meta_description'] = meta_description;
        }
        if (!slug?.replace(/\s/g, '').length <= 0) {
          listingData['slug'] = SlugChecker(slug);
        } else {
          listingData['slug'] = SlugChecker(replace_special_characters(title));
        }

        // post listing data
        axios
          .post('/api/l/edit_listing', {
            productId,
            data: { listing: listingData },
          })
          .then((res) => {
            push_data_layer({
              event: 'edit_listing',
              data: {
                title: title,

                price: price,
              },
            });
            setEditProductLoading(false);
            setShowSuccessMessage(true);
          })
          .catch((error) => {
            setShowError(true);
            setError_message(error.response.data.message);
            setEditProductLoading(false);
          });
      }
    }
  } catch (error) {
    console.log(error);
    setShowError(true);
    setError_message(error.response.data.message);
    setEditProductLoading(false);
  }
};
