/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useDispatch } from 'react-redux';
import {
  accountAttribute,
  categories,
  clearAttributes,
  clearStoreState,
  getPickupAddress,
  storeSelector,
  tenantShippingMethods,
} from '../../../store/feature/storeSlice';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import Attributes from './Attributes';
import PopUp from '../../Shared/PopUp/PopUp';
import OutsideClickHandler from 'react-outside-click-handler';
import { edit_store_click } from './editButton';
import { useRouter } from 'next/dist/client/router';
import { MultiSelect } from 'react-multi-select-component';
import { Add_store_text } from '../../../constant/Translation/AddStore_text';
import { General_text } from '../../../constant/Translation/General';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import SearchAddress from '../../Shared/SearchAddress';
import Modal from '../../Shared/Modal/Modal';
import AddressForm from './AddressForm';
import { Address_text } from '../../../constant/Translation/Address';
import SelectDropDown from '../../Shared/SelectDropDown';
import {
  category_serialization,
  fetch_all_categories,
} from '../../../constant/fetch_all_categories';
import { validateImageSize } from '../../../utils/ImageSizeValidate';
import Composer2 from '../../Shared/Composer/Composer2';

const EditStoreForm = ({
  my_stores,
  accountId,
  accounts_configs,
  my_account_details,
}) => {
  const [imagePath, setImagePath] = useState(null);
  const [files, setFiles] = useState(null);
  const [name, setName] = useState(null);
  const [slug, setSlug] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [selected_category, setSelectedCategory] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [preferenceShippingMethod, setPreferenceShippingMethod] =
    useState(null);
  const [attributeData, setAttributeData] = useState([]);
  const [description, setDescription] = useState('');
  const [addressSearchKey, setAddressSearchKey] = useState('');
  const [full_location, setFullLocation] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [shippingCharge, setShippingCharge] = useState(0);

  const [editStoreLoading, setEditStoreLoading] = useState(false);
  const router = useRouter();

  const { auth_key } = useSelector(authSelector);
  const dispatch = useDispatch();

  // address
  const [address_state, setAddressState] = useState(null);
  const [showPickupAddressForm, setShowPickupAddressForm] = useState(false);
  const [pickup_address_id, set_pickup_address_id] = useState(null);

  useEffect(() => {
    //store calling
    dispatch(categories({ prams: { parent: 0, type: 'accounts' } }));
    dispatch(tenantShippingMethods());
    dispatch(getPickupAddress({ bodyParam: { type: 'pickup' } }));

    // State Setting
    my_account_details?.images.length > 0 &&
      setImagePath({
        path: my_account_details?.images[0],
      });
    setName(my_account_details?.name);
    setSlug(my_account_details?.slug);
    my_account_details?.description &&
      setDescription(my_account_details?.description);
    setCoordinates({
      latitude: my_account_details?.coordinates?.latitude,
      longitude: my_account_details?.coordinates?.longitude,
    });
    setAddressSearchKey(my_account_details?.location?.formatted_address);
    TYPE_CONSTANT.ADDRESS_CONFIGS.map_type == 'google_map' &&
      setFullLocation(my_account_details?.location);
    my_account_details?.categories[0]?.id &&
      setSelectedCategory(
        category_serialization(my_account_details?.categories[0])
      );

    setShippingCharge(Number(my_account_details?.shipping_charges?.flat));

    my_account_details?.shipping_methods.length > 0 &&
      setPreferenceShippingMethod(
        my_account_details?.shipping_methods.map((item) => {
          return { value: item.id, label: item.name, type: item.type };
        })
      );
    my_account_details?.categories[0]?.id &&
      dispatch(
        accountAttribute({
          prams: {
            category_id: my_account_details?.categories[0]?.id,
            type: 'accounts',
          },
          authKey: auth_key,
        })
      );

    if (my_account_details?.attributes.length > 0) {
      my_account_details?.attributes?.filter((attr) => {
        if (
          (attr.field_type === 1 || attr.field_type === 2) &&
          attr.values?.length > 0
        ) {
          setAttributeData((prevState) => [
            ...prevState,
            {
              id: attr.id,
              values: attr.values.map((item) => item.id),
            },
          ]);
        } else if (
          (attr.field_type === 3 || attr.field_type === 4) &&
          attr.values?.length > 0
        ) {
          setAttributeData((prevState) => [
            ...prevState,
            { id: attr.id, values: attr.values.map((item) => item) },
          ]);
        } else if (attr.field_type === 5 && attr.values?.length > 0) {
          setAttributeData((prevState) => [
            ...prevState,
            {
              values: attr.values,
              id: attr.id,
            },
          ]);
        } else if (
          (attr.field_type === 6 ||
            attr.field_type === 7 ||
            attr.field_type === 8 ||
            attr.field_type === 9 ||
            attr.field_type === 10 ||
            attr.field_type === 11 ||
            attr.field_type === 12) &&
          attr.values?.length > 0
        ) {
          setAttributeData((prevState) => [
            ...prevState,
            {
              values: attr.values,
              id: attr.id,
            },
          ]);
        }
      });
    }

    return () => {
      dispatch(clearAttributes());
    };
  }, [0]);

  // Store selector
  const {
    isError,
    errorMessage,
    account_categories,
    tenant_shipping_methods,
    pickup_addresses,
    attributes,
  } = useSelector(storeSelector);

  useEffect(() => {
    if (pickup_addresses && pickup_addresses?.length > 0) {
      setAddressState(pickup_addresses[0]);
      set_pickup_address_id(pickup_addresses[0].id);
    }
  }, [pickup_addresses]);

  // all_categories
  useEffect(() => {
    if (account_categories !== null) {
      if (account_categories?.length > 0) {
        setAllCategories(fetch_all_categories(account_categories));
      } else {
        dispatch(
          accountAttribute({
            prams: { type: 'accounts' },
          })
        );
      }
    }

    if (account_categories !== null) {
      if (account_categories?.length === 1 && selected_category === null) {
        setSelectedCategory({
          label: account_categories[0].name,
          value: account_categories[0].id,
        });
      }
    }
  }, [account_categories]);

  // close pop up
  const closePopUP = () => {
    dispatch(clearStoreState());
    setShowError(false);
    setError_message('');
  };

  //
  const [isShowPickupAddress, setIsShowPickupAddress] = useState(false);
  useEffect(() => {
    const shouldShowPickupAddress = () => {
      if (!preferenceShippingMethod?.length) {
        return TYPE_CONSTANT?.ADDRESS_CONFIGS
          ?.get_account_pickup_address_always;
      }

      const hasPickupMethod =
        preferenceShippingMethod?.some((method) => method.type === 'pickup') &&
        accounts_configs?.enable_shipping_methods_preference;

      const hasDeliveryWithPickup =
        preferenceShippingMethod?.some(
          (method) => method.type === 'delivery'
        ) && accounts_configs?.get_pickup_address_for_delivery;

      const hasSendcloudDelivery = preferenceShippingMethod?.some(
        (method) => method.type === 'delivery' && method.channel === 'sendcloud'
      );

      return hasPickupMethod || hasDeliveryWithPickup || hasSendcloudDelivery;
    };

    setIsShowPickupAddress(shouldShowPickupAddress());
  }, [
    accounts_configs?.get_pickup_address_for_delivery,
    preferenceShippingMethod,
  ]);

  // on select category
  const onSelectCategory = (item) => {
    setSelectedCategory(item);
    setAttributeData(null);
    dispatch(clearAttributes());
    dispatch(
      accountAttribute({
        prams: { category_id: item.value, type: 'accounts' },
        authKey: auth_key,
      })
    );
  };

  return (
    <div className=" w-full">
      {showPickupAddressForm && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowPickupAddressForm(false);
            }}
          >
            <AddressForm
              setShowPickupAddressForm={setShowPickupAddressForm}
              address_state={address_state}
              setAddressState={setAddressState}
            />
          </OutsideClickHandler>
        </Modal>
      )}
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearStoreState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <h3 className=" text-center font-semibold text-2xl text-primary mb-4">
        {Add_store_text.addstore.addstore_edit_store}
      </h3>
      {accounts_configs?.account_detail_note && (
        <div
          className="mt-1.5 mb-6 bg-green-100 border-t-2 border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
          role="alert"
        >
          <div className="flex items-start">
            <svg
              className="flex-none fill-current h-4 w-4 text-green-500 mr-4 mt-0.5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>

            <div className="flex-grow">
              <p className="text-sm">{accounts_configs?.account_detail_note}</p>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 gap-6">
        <div className="block">
          <span className="text-gray-700">
            {Add_store_text.addstore.imagefield}
          </span>
          <input
            id="imageButton"
            type="file"
            value={''}
            className=" hidden"
            accept=".png, .jpg ,.svg , .gif, .webp, .jpeg"
            placeholder=""
            onChange={(e) => {
              if (validateImageSize(e.target.files[0])) {
                e.target.files.length > 0 &&
                  (setImagePath({
                    id: e.target.files[0].name,
                    path: URL.createObjectURL(e.target.files[0]),
                  }),
                  setFiles(e.target.files[0]));
              } else {
                setShowError(true);
                setError_message(Add_store_text.addstore.image_size_alert);
                return false;
              }
            }}
          />
          <div>
            {imagePath !== null ? (
              <div className=" relative w-[100px] mt-4">
                <img
                  src={imagePath.path}
                  className="w-[100px] h-[100px] object-cover"
                  alt="account image"
                />
                <button
                  className=" absolute -top-2 -right-2 text-primary "
                  onClick={() => {
                    return setImagePath(null), setFiles(null);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <button
                className=" w-[100px]  h-[100px] flex justify-center items-center  mt-3  bg-gray-100 text-sm rounded-button "
                onClick={() => document.getElementById('imageButton').click()}
              >
                {Add_store_text.addstore.add_image}
              </button>
            )}
          </div>
        </div>
        <label className="block">
          <span className="text-gray-700">
            {Add_store_text.addstore.store_name}
          </span>
          <input
            value={name}
            type="text"
            className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
            placeholder=""
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        {accounts_configs?.enable_account_slug && (
          <label className="block">
            <span className="text-gray-700">
              {Add_store_text.addstore.slug}
            </span>
            <input
              value={slug}
              type="text"
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              placeholder=""
              onChange={(e) => setSlug(e.target.value)}
            />
          </label>
        )}
        {accounts_configs?.show_description != false && (
          <div className="block">
            <span className="text-gray-700">
              {Add_store_text.addstore.description}
            </span>
            {accounts_configs?.account_enable_formatted_text_description !=
            false ? (
              <Composer2
                contents={description}
                setContents={setDescription}
                mainClass={' border-b-2 border-gray-200 '}
              />
            ) : (
              <textarea
                value={description}
                className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                rows="4"
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            )}
          </div>
        )}

        {/*  */}
        {accounts_configs?.accounts_show_shipping_charges && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_store_text.addstore.shipping_charge}
            </span>
            <input
              value={shippingCharge}
              type="number"
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              placeholder="0"
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setShippingCharge(Number(e.target.value));
                }
              }}
              onWheel={(event) => event.currentTarget.blur()}
            />
          </label>
        )}

        {/*  */}

        {accounts_configs?.account_address_enabled && (
          <label className="block ">
            <span className="text-gray-700">
              {Add_store_text.addstore.address}
            </span>
            <SearchAddress
              setCoordinates={setCoordinates}
              addressSearchKey={addressSearchKey}
              setAddressSearchKey={setAddressSearchKey}
              full_location={full_location}
              setFullLocation={setFullLocation}
            />
          </label>
        )}

        {account_categories?.length > 0 && (
          <label className="block">
            <span className="text-gray-700 ">
              {Add_store_text.addstore.category}
            </span>
            <SelectDropDown
              drop_down_values={allCategories?.map((item) => {
                return { label: item.name, value: item.id };
              })}
              setSelectedItem={(item) => {
                if (item) {
                  onSelectCategory(item);
                } else {
                  setSelectedCategory(null);
                  setAttributeData(null);
                  dispatch(clearAttributes());
                }
              }}
              selectedItem={selected_category}
            />
          </label>
        )}

        <div className="  flex flex-col gap-6">
          <Attributes
            attributeData={attributeData}
            setAttributeData={setAttributeData}
          />
        </div>

        {/* Shipping */}
        {accounts_configs?.enable_shipping_methods_preference && (
          <div>
            <label className="block">
              <span className="text-gray-700">
                {' '}
                {Add_store_text.addstore.preferred_shipment}
              </span>
            </label>
            {accounts_configs?.shipping_methods_preference_note &&
              accounts_configs?.shipping_methods_preference_note.length > 1 && (
                <div
                  className="mt-1.5 bg-green-100 border-t-2 border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                  role="alert"
                >
                  <div className="flex items-start">
                    <svg
                      className="flex-none fill-current h-4 w-4 text-green-500 mr-4 mt-0.5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>

                    <div className="flex-grow">
                      <p className="text-sm">
                        {accounts_configs?.shipping_methods_preference_note}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            {tenant_shipping_methods?.length > 0 && (
              <MultiSelect
                options={tenant_shipping_methods?.map((item) => {
                  return {
                    value: item?.id,
                    label: item.name,
                    type: item.type,
                  };
                })}
                value={
                  preferenceShippingMethod?.length > 0
                    ? preferenceShippingMethod?.map((item) => {
                        if (
                          tenant_shipping_methods?.filter(
                            (shipping_method) =>
                              item.value == shipping_method?.id
                          )?.length > 0
                        ) {
                          const shipping_method =
                            tenant_shipping_methods?.filter(
                              (shipping_method) =>
                                item.value == shipping_method?.id
                            );
                          return {
                            value: shipping_method[0]?.id,
                            label: shipping_method[0]?.name,
                            type: shipping_method[0]?.type,
                          };
                        }
                      })
                    : []
                }
                onChange={(e) => {
                  setPreferenceShippingMethod(e);
                }}
                labelledBy={General_text.general.select}
                overrideStrings={{
                  allItemsAreSelected: General_text.general.allItemsAreSelected,
                  clearSearch: General_text.general.clearSearch,
                  clearSelected: General_text.general.clearSelected,
                  noOptions: General_text.general.noOptions,
                  search: General_text.general.search,
                  selectAll: General_text.general.selectAll,
                  selectAllFiltered: General_text.general.selectAllFiltered,
                  selectSomeItems: General_text.general.selectSomeItems,
                  create: General_text.general.create,
                }}
              />
            )}
          </div>
        )}

        {/*Pickup Address  */}

        {isShowPickupAddress && (
          <div className="block ">
            <div className="flex items-center justify-between gap-2">
              <p className="text-gray-700 ">
                {Address_text.address.add_pickup_address}
              </p>
              <button
                onClick={() => setShowPickupAddressForm(true)}
                className="btn-sm  bg-primary hover:bg-transparent border border-primary text-white hover:text-primary"
              >
                {address_state && Object.entries(address_state)?.length
                  ? Address_text.address.edit_address
                  : Address_text.address.add_address}
              </button>
            </div>

            <p className="mt-2">
              {address_state !== null &&
                `${address_state?.name ? address_state?.name : ''}, ${
                  address_state?.phone_number ? address_state?.phone_number : ''
                }, ${
                  address_state?.address_line_1
                    ? address_state?.address_line_1
                    : ''
                }, 
              ${
                TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_address2
                  ? address_state?.address_line_2 + ','
                  : ''
              } ${
                  TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_country
                    ? address_state?.country + ','
                    : ''
                } ${
                  TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_state
                    ? address_state?.state + ','
                    : ''
                } ${
                  TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_zipcode
                    ? address_state?.post_code + ','
                    : ''
                }`}
            </p>
          </div>
        )}
      </div>
      <div className=" mt-9 flex justify-center  ">
        <button
          className="text-white px-7 py-2 rounded-button bg-primary  flex items-center justify-center gap-3"
          id="edit-account"
          onClick={() =>
            edit_store_click(
              files,
              imagePath,
              name,
              slug,
              description,
              coordinates,
              full_location,
              preferenceShippingMethod,
              selected_category ? selected_category.value : null,
              attributeData,
              setShowError,
              setError_message,
              auth_key,
              dispatch,
              accountId,
              setEditStoreLoading,
              router,
              accounts_configs,
              address_state,
              pickup_address_id,
              attributes,
              shippingCharge
            )
          }
        >
          {editStoreLoading && (
            <svg
              className="animate-spin -ml-1  h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {Add_store_text.addstore.addstore_edit_store}
        </button>
      </div>
    </div>
  );
};

export default EditStoreForm;
